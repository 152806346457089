<template>
  <v-container class="spacing-playground pa-1" style="margin-top: 100px">
    <v-row no-gutters>
      <v-col class="pa-1 d-flex flex-column" cols="12" xs="12" sm="12" md="2">
        <v-card class="elevation-5 flex d-flex flex-column">
          <div
            class="fb-page"
            data-href="https://www.facebook.com/mercanews.officiel"
            data-tabs="timeline"
            data-small-header="false"
            data-adapt-container-width="true"
            data-hide-cover="false"
            data-show-facepile="false"
            data-height="400px"
          >
            <blockquote
              cite="https://www.facebook.com/mercanews.officiel"
              class="fb-xfbml-parse-ignore"
            >
              <a href="https://www.facebook.com/mercanews.officiel"
                >MercaNews</a
              >
            </blockquote>
          </div>
          <v-row no-gutters justify="center">
            <v-col align="center" :cols="12" xs="12" sm="12" md="12">
              <h4>Suivez nous !</h4>
            </v-col>
            <v-card
              v-for="(item, i) in socialMedia"
              :key="i"
              :href="item.href"
              class="flex d-flex flex-column"
              outlined
              tile
            >
              <v-icon large :color="item.color">{{ item.icon }}</v-icon>
            </v-card>
          </v-row>
        </v-card>
      </v-col>
      <v-col class="pa-1 d-flex flex-column" cols="12" xs="12" sm="12" md="8">
        <v-card class="elevation-5 flex d-flex flex-column">
          <v-carousel hide-delimiters show-arrows :cycle="true">
            <v-carousel-item
              v-for="(card, id) in listeArticleCarroussel"
              :key="id"
              :src="card.image"
              v-on:error="card.image = mercanews"
              style="cursor: pointer"
              @click="redirectToDetailNews(card)"
              reverse-transition="fade-transition"
              transition="fade-transition"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            >
              <div class="category">
                <span v-text="card.category" class="categorytitle" />
              </div>
              <div class="item__content" dir="auto">
                <h1
                  v-text="card.title"
                  class="black--text"
                  style="font-weight: bold; font-size: 18px; cursor: pointer"
                />
                <h4 v-text="card.description" />
              </div>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-col>
      <v-col class="pa-1 d-flex flex-column" cols="12" xs="12" sm="12" md="2">
        <v-card class="elevation-5 flex d-flex flex-column">
          <div class="text-center section-headind">
            <h3>
              Avec
              <em> Mercanews </em>
              Accédez aux dernières
              <span class="news"> News </span>
              sous toutes ses formes.
            </h3>
            <br />
            <div class="text-center">
              <a
                href="https://play.google.com/store/apps/details?id=com.mercarue.mercanews&hl=fr"
                target="_blank"
                rel="noopener noreferrer"
                style="border: medium none; text-decoration: none"
              >
                <v-btn style="background-color: black; color: white">
                  Get it now
                </v-btn>
              </a>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="pa-1 d-flex flex-column" xs="12" sm="12" md="3">
        <v-card
          class="elevation-5 flex d-flex flex-column"
          outlined
          tile
          v-if="fluxCulture"
          @click="redirectToDetailNews(fluxCulture)"
          target="_blank"
        >
          <v-list-item>
            <v-list-item-avatar>
              <v-img :src="fluxCulture.sourceImage"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                style="font-size: large"
                v-text="fluxCulture.category"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-text="fluxCulture.sourceName"
              ></v-list-item-subtitle>
            </v-list-item-content>
            <v-spacer></v-spacer>
          </v-list-item>
          <v-img
            :src="fluxCulture.image"
            v-on:error="fluxCulture.image = mercanews"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="200px"
          >
          </v-img>
          <v-card-actions style="height: 90px" dir="auto">
            <v-card-title class="actutitle" v-text="fluxCulture.title">
            </v-card-title>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col class="pa-1 d-flex flex-column" xs="12" sm="12" md="6">
        <v-card class="elevation-5 flex d-flex flex-column" outlined tile>
          <iframe
            class="iframe-youtube"
            src="https://www.youtube.com/embed/videoseries?list=PLn72OCWLyVzWvVERXQ8gVE9_0sfSgC40o&autoplay=1&mute=1&loop=1"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </v-card>
      </v-col>
      <v-col class="pa-1 d-flex flex-column" xs="12" sm="12" md="3">
        <v-card
          class="elevation-5 flex d-flex flex-column"
          outlined
          tile
          v-if="fluxCelebrite"
          @click="redirectToDetailNews(fluxCelebrite)"
          target="_blank"
        >
          <v-list-item>
            <v-list-item-avatar>
              <v-img :src="fluxCelebrite.sourceImage"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                style="font-size: large"
                v-text="fluxCelebrite.category"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-text="fluxCelebrite.sourceName"
              ></v-list-item-subtitle>
            </v-list-item-content>
            <v-spacer></v-spacer>
          </v-list-item>
          <v-img
            :src="fluxCelebrite.image"
            v-on:error="fluxCelebrite.image = mercanews"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="200px"
          >
          </v-img>
          <v-card-actions style="height: 90px" dir="auto">
            <v-card-title class="actutitle" v-text="fluxCelebrite.title">
            </v-card-title>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="pa-1 d-flex flex-column" xs="12" sm="12" md="2">
        <v-card class="elevation-5 flex d-flex flex-column">
          <Adsense
            data-ad-client="ca-pub-2248195857071378"
            data-ad-slot="7130091720"
          >
          </Adsense>
        </v-card>
      </v-col>
      <v-col class="pa-1 d-flex flex-column" xs="12" sm="12" md="4">
        <v-card
          class="elevation-5 flex d-flex flex-column"
          outlined
          tile
          v-if="fluxPolitique"
          @click="redirectToDetailNews(fluxPolitique)"
          target="_blank"
        >
          <v-list-item>
            <v-list-item-avatar>
              <v-img :src="fluxPolitique.sourceImage"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                style="font-size: large"
                v-text="fluxPolitique.category"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-text="fluxPolitique.sourceName"
              ></v-list-item-subtitle>
            </v-list-item-content>
            <v-spacer></v-spacer>
          </v-list-item>
          <v-img
            :src="fluxPolitique.image"
            v-on:error="fluxPolitique.image = mercanews"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="200px"
          >
          </v-img>
          <v-card-actions style="height: 90px" dir="auto">
            <v-card-title class="actutitle" v-text="fluxPolitique.title">
            </v-card-title>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col class="pa-1 d-flex flex-column" xs="12" sm="12" md="4">
        <v-card
          class="elevation-5 flex d-flex flex-column"
          outlined
          tile
          v-if="fluxSociete"
          @click="redirectToDetailNews(fluxSociete)"
          target="_blank"
        >
          <v-list-item>
            <v-list-item-avatar>
              <v-img :src="fluxSociete.sourceImage"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                style="font-size: large"
                v-text="fluxSociete.category"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-text="fluxSociete.sourceName"
              ></v-list-item-subtitle>
            </v-list-item-content>
            <v-spacer></v-spacer>
          </v-list-item>
          <v-img
            :src="fluxSociete.image"
            v-on:error="fluxSociete.image = mercanews"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="200px"
          >
          </v-img>
          <v-card-actions style="height: 90px" dir="auto">
            <v-card-title class="actutitle" v-text="fluxSociete.title">
            </v-card-title>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col class="pa-1 d-flex flex-column" xs="12" sm="12" md="2">
        <v-card class="elevation-5 flex d-flex flex-column">
          <Adsense
            data-ad-client="ca-pub-2248195857071378"
            data-ad-slot="7130091720"
          >
          </Adsense>
        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="pa-1 d-flex flex-column" xs="12" sm="12" md="3">
        <v-card class="elevation-5 flex d-flex flex-column">
          <Adsense
            data-ad-client="ca-pub-2248195857071378"
            data-ad-slot="7130091720"
          >
          </Adsense>
        </v-card>
      </v-col>
      <v-col class="pa-1 d-flex flex-column" xs="12" sm="12" md="6">
        <v-card
          class="elevation-5 flex d-flex flex-column"
          outlined
          tile
          v-if="fluxInternational"
          @click="redirectToDetailNews(fluxInternational)"
          target="_blank"
        >
          <v-list-item>
            <v-list-item-avatar>
              <v-img :src="fluxInternational.sourceImage"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                style="font-size: large"
                v-text="fluxInternational.category"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-text="fluxInternational.sourceName"
              ></v-list-item-subtitle>
            </v-list-item-content>
            <v-spacer></v-spacer>
          </v-list-item>
          <v-img
            :src="fluxInternational.image"
            v-on:error="fluxInternational.image = mercanews"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="200px"
          >
          </v-img>
          <v-card-actions style="height: 90px" dir="auto">
            <!--  <v-spacer></v-spacer> -->
            <v-card-title class="actutitle" v-text="fluxInternational.title">
            </v-card-title>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col class="pa-1 d-flex flex-column" xs="12" sm="12" md="3">
        <v-card class="elevation-5 flex d-flex flex-column">
          <Adsense
            data-ad-client="ca-pub-2248195857071378"
            data-ad-slot="7130091720"
          >
          </Adsense>
        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="pa-1 d-flex flex-column" xs="12" sm="12" md="4">
        <v-card
          class="elevation-5 flex d-flex flex-column"
          outlined
          tile
          v-if="fluxEconomie"
          @click="redirectToDetailNews(fluxEconomie)"
          target="_blank"
        >
          <v-list-item>
            <v-list-item-avatar>
              <v-img :src="fluxEconomie.sourceImage"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                style="font-size: large"
                v-text="fluxEconomie.category"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-text="fluxEconomie.sourceName"
              ></v-list-item-subtitle>
            </v-list-item-content>
            <v-spacer></v-spacer>
          </v-list-item>
          <v-img
            :src="fluxEconomie.image"
            v-on:error="fluxEconomie.image = mercanews"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="200px"
          >
          </v-img>
          <v-card-actions style="height: 90px" dir="auto">
            <v-card-title class="actutitle" v-text="fluxEconomie.title">
            </v-card-title>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col class="pa-1 d-flex flex-column" xs="12" sm="12" md="4">
        <v-card
          class="elevation-5 flex d-flex flex-column"
          outlined
          tile
          v-if="fluxHiTechAndScience"
          @click="redirectToDetailNews(fluxHiTechAndScience)"
          target="_blank"
        >
          <v-list-item>
            <v-list-item-avatar>
              <v-img :src="fluxHiTechAndScience.sourceImage"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                style="font-size: large"
                v-text="fluxHiTechAndScience.category"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-text="fluxHiTechAndScience.sourceName"
              ></v-list-item-subtitle>
            </v-list-item-content>
            <v-spacer></v-spacer>
          </v-list-item>
          <v-img
            :src="fluxHiTechAndScience.image"
            v-on:error="fluxHiTechAndScience.image = mercanews"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="200px"
          >
          </v-img>
          <v-card-actions style="height: 90px" dir="auto">
            <v-card-title class="actutitle" v-text="fluxHiTechAndScience.title">
            </v-card-title>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col class="pa-1 d-flex flex-column" xs="12" sm="12" md="4">
        <v-card
          class="elevation-5 flex d-flex flex-column"
          outlined
          tile
          v-if="fluxSport"
          @click="redirectToDetailNews(fluxSport)"
          target="_blank"
        >
          <v-list-item>
            <v-list-item-avatar>
              <v-img :src="fluxSport.sourceImage"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                style="font-size: large"
                v-text="fluxSport.category"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-text="fluxSport.sourceName"
              ></v-list-item-subtitle>
            </v-list-item-content>
            <v-spacer></v-spacer>
          </v-list-item>
          <v-img
            :src="fluxSport.image"
            v-on:error="fluxSport.image = mercanews"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="200px"
          >
          </v-img>
          <v-card-actions style="height: 90px" dir="auto">
            <v-card-title class="actutitle" v-text="fluxSport.title">
            </v-card-title>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      bottom
      right
      color="light blue"
      @click="toTop"
    >
      <v-icon>mdi-arrow-up-bold-outline</v-icon>
    </v-btn>
    <News />
  </v-container>
</template>

<script>
import "firebase/compat/firestore";
import db from "../utils/firebaseInit";
import NodesName from "../utils/nodesName";
import NodesFieldName from "../utils/nodesFieldName";
import MercanewsLogo from "../assets/Mercanews.png";
import FunctionUtils from "../utils/functions";
import FirebaseDB from "../utils/firebaseDB";
import News from "../components/News.vue";

export default {
  components: { News },
  data() {
    return {
      listeArticleCarroussel: [],
      articleForOtherCase: [],
      loader: null,
      loading5: false,
      nextItem: 1,
      draggable: false,
      resizable: false,
      responsive: true,
      index: 0,
      mercanews: MercanewsLogo,
      hover: false,
      fluxRandom: null,
      fluxSport: null,
      fluxInternational: null,
      fluxPolitique: null,
      fluxSociete: null,
      fluxCulture: null,
      fluxCelebrite: null,
      fluxEconomie: null,
      fluxHiTechAndScience: null,
      fab: false,
      sourceLogo: [],
      socialMedia: [
        {
          text: "Facebook",
          href: "https://www.facebook.com/mercanews.officiel",
          icon: "mdi-facebook",
          color: "blue",
        },
        {
          text: "Instagram",
          href: "https://www.instagram.com/mercanews.officiel/",
          icon: "mdi-instagram",
          color: "brown",
        },
        {
          text: "Youtube",
          href: "https://www.youtube.com/channel/UCawEEHUjvtwqRlVB5WgtxBA",
          icon: "mdi-youtube",
          color: "red",
        },
      ],
    };
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },

    toTop() {
      this.$vuetify.goTo(0);
    },
    redirectToDetailNews(item) {
      if (item.sourceName == "Mercanews") {
        let routeData = this.$router.resolve({
          name: "DetailNews",
          params: {
            uniqueId: item.uniqueId,
          },
        });
        window.open(routeData.href);
      } else {
        window.open(item.link);
      }
    },
    async getArticleForCarrousel() {
      await db
        .collection(NodesName.RSS_FEED)
        .where(NodesFieldName.ARCHIVED, "==", false)
        .orderBy(NodesFieldName.ISODATE, "desc")
        .limit(5)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.listeArticleCarroussel.push({
              category: doc.data().libelle,
              title: doc.data().title,
              description:
                doc.data().description.length > 290
                  ? doc.data().description.substring(0, 290) + "..."
                  : doc.data().description,
              image: FunctionUtils.getStringImageQuality(doc.data().image),
              sourceName: doc.data().sourceName,
              link: doc.data().link,
            });
          });
        });
    },
  },

  async created() {
    if (this.sourceLogo.length === 0) {
      // Si les logos des sources ne sont pas dans le local storage alors les récupérer depuis la base de données
      this.sourceLogo = await FirebaseDB.getSourcesWithImages();
      // Les ajouter dans le local storage
      localStorage.sourceLogo = JSON.stringify(
        Array.from(this.sourceLogo.entries())
      );
    }

    this.getArticleForCarrousel();

    this.fluxSport = await FirebaseDB.getArticleFromDatabase(
      5,
      "sport",
      this.sourceLogo
    );

    this.fluxInternational = await FirebaseDB.getArticleFromDatabase(
      5,
      "international",
      this.sourceLogo
    );

    this.fluxPolitique = await FirebaseDB.getArticleFromDatabase(
      5,
      "politique",
      this.sourceLogo
    );

    this.fluxSociete = await FirebaseDB.getArticleFromDatabase(
      5,
      "societe",
      this.sourceLogo
    );

    this.fluxCulture = await FirebaseDB.getArticleFromDatabase(
      5,
      "culture",
      this.sourceLogo
    );

    this.fluxCelebrite = await FirebaseDB.getArticleFromDatabase(
      5,
      "celebrite",
      this.sourceLogo
    );

    this.fluxEconomie = await FirebaseDB.getArticleFromDatabase(
      5,
      "economie",
      this.sourceLogo
    );

    this.fluxHiTechAndScience = await FirebaseDB.getArticleFromDatabase(
      5,
      "sciences",
      this.sourceLogo
    );
  },
};
</script>
<style>
.item__content {
  position: absolute;
  bottom: 0;
  height: 30%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.836);
  padding: 3px;
  text-align: justify;
}
.category {
  width: 0%;
  height: 0%;
}
.categorytitle {
  background-color: rgba(0, 204, 255, 0.8) !important;
  border-radius: 10px 40px 40px 10px;
  display: inline-block;
  text-align: center;
  width: 100px;
  font-weight: bold;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
h2 {
  margin-top: 10%;
  font-size: 35px;
  font-weight: 600;
  color: #2a2a2a;
  line-height: 40px;
  text-align: center;
}
.section-heading {
  letter-spacing: 0.25px;
  position: relative;
  z-index: 2;
}
em {
  color: #19b4bf;
  font-style: normal;
}
.news {
  color: #ea3490;
}
*.iframe-youtube {
  height: 100% !important;
  width: 100% !important;
}
.actutitle {
  font-size: 13px;
  font-weight: bold;
  word-break: normal;
  text-align: justify;
}
.v-list-item--link::before {
  background: none;
}
.theme--light.v-sheet--outlined {
  border: rgba(0, 0, 0, 0.12);
}
</style>
