<template>
  <v-container class="spacing-playground pa-1" fluid>
    <v-row no-gutters>
      <v-col
        v-for="(flux, index) in news"
        v-bind:key="index + '-flux'"
        :cols="12"
        xs="12"
        sm="12"
        md="4"
        class="pa-1 d-flex flex-column"
      >
        <v-card
          class="elevation-5 flex d-flex flex-column"
          outlined
          tile
          v-if="flux"
          v-on:click.prevent="setNumberOfViews(flux)"
          @click="redirectToDetailNews(flux)"
        >
          <v-list-item>
            <v-list-item-avatar>
              <v-img :src="flux.sourceImage"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                style="font-size: large"
                v-text="flux.category"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-text="flux.sourceName"
              ></v-list-item-subtitle>
            </v-list-item-content>
            <v-spacer></v-spacer>
          </v-list-item>
          <v-img
            :src="flux.image"
            v-on:error="flux.image = mercanews"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="200px"
          >
          </v-img>
          <v-card-actions style="height: 90px" dir="auto">
            <v-card-title class="actutitle" v-text="flux.title"> </v-card-title>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <InfiniteLoading @infinite="infiniteHandler">
      <div slot="no-more">
        <Footer />
      </div>
      <div slot="no-results">
        <Footer />
      </div>
    </InfiniteLoading>
  </v-container>
</template>

<script>
import "firebase/compat/firestore";
import InfiniteLoading from "vue-infinite-loading";
import FirebaseDB from "../utils/firebaseDB";
import MercanewsLogo from "../assets/Mercanews.png";
import Footer from "../components/Footer.vue";

export default {
  components: {
    InfiniteLoading,
    Footer,
  },
  data() {
    return {
      news: [],
      lastDocSnapshot: null,
      mercanews: MercanewsLogo,
      totalFluxCount: 0,
    };
  },
  methods: {
    redirectToDetailNews(item) {
      if (item.sourceName == "Mercanews") {
        let routeData = this.$router.resolve({
          name: "DetailNews",
          params: {
            uniqueId: item.uniqueId,
          },
        });
        window.open(routeData.href, "_blank");
      } else {
        window.open(item.link, "_blank");
      }
    },
    async fetchFlux() {
      let fluxRef =
        FirebaseDB.getArticleFromDatabaseForInfiniteScrollReference();

      if (this.lastDocSnapshot) {
        fluxRef = fluxRef.startAfter(this.lastDocSnapshot);
      }
      const fluxSnap = await fluxRef.get();
      this.lastDocSnapshot = fluxSnap.docs[fluxSnap.docs.length - 1];
      const result = await FirebaseDB.getArticleFromDatabaseForInfiniteScroll(
        fluxSnap,
        new Map(JSON.parse(localStorage.sourceLogo))
      );

      this.news.push(...result);
      return result.length;
    },
    async infiniteHandler($state) {
      const newFluxCount = await this.fetchFlux();
      this.totalFluxCount = this.totalFluxCount + newFluxCount;

      if (newFluxCount > 0 && this.totalFluxCount < 100) {
        return $state.loaded(); // More news to come
      }

      return $state.complete(); // Done with the news
    },
    async setNumberOfViews(flux) {
      await FirebaseDB.setNumberOfViews(flux.uniqueId);
      //window.open(flux.link, "_blank");
    },
  },
};
</script>

<style scoped></style>
